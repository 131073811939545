import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Slider } from "./Slider";
import { Provider, Context } from "./../context";
import uniqid from "uniqid";
import "./swiper_add.css";

export function Gallery(props) {
  const context = useContext(Context);

  return (
    <>
    <div className="slider0">
      <Slider blockId="main" template="main">
        {context.data["gallery"]
          // .filter((one) => {
          //   let cat = one["categories"][0];
          //   // let catName = context["handles"].getCategoryById(cat);
          //   let val = catName
          //     ? catName["name"] === match.params["catId"]
          //     : false;
          //   if (!match.params["catId"]) val = true;
          //   let template = one["template"] === "slider" ? true : false;
          //   return val && template ? true : false;
          // })
          .map((oneImage, i) => {
            return (
              <div key={`slide-${uniqid()}`} className="swiper-slide">
                <div className="slide_cont">
                  <img
                    src={`${oneImage["image"][0]["url"]}`}
                    className="slide_img"
                    // width="100"
                  />
                </div>
              </div>
            );
          })}
      </Slider>
      </div>

      <div className="slider1">
        <div className="thumb0">
          <div className="thumb_bg"/>
      <Slider
        blockId="th"
        thumbsFor="main"
        template="th"
        arrows={false}
      >
        {context.data["gallery"]
          // .filter((one) => {
          //   let cat = one["categories"][0];
          //   // let catName = context["handles"].getCategoryById(cat);
          //   let val = catName
          //     ? catName["name"] === match.params["catId"]
          //     : false;
          //   if (!match.params["catId"]) val = true;
          //   let template = one["template"] === "slider" ? true : false;
          //   return val && template ? true : false;
          // })
          .map((oneImage, i) => {
            return (
              <div key={`slide-${uniqid()}`} className="swiper-slide  swiper-slide--th">
                <div className="slide_cont slide_cont--th">
                  <img
                    src={`${oneImage["image"][0]["url"]}`}
                    className="slide_img"
                    // width="100"
                  />
                </div>
              </div>
            );
          })}
      </Slider>

      </div></div>
    </>
  );
}
