import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Slider } from "./components/Slider";
import { Provider, Context } from "./context";
import uniqid from "uniqid";
import { Gallery } from "./components/Gallery";
import { Route, Redirect } from "react-router";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Layout } from './components/Layout';

function App() {

  const context = useContext(Context);

  return (
    <div className="App body">

      <Provider>
      <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={({ history }) => <>
                <Layout>
                  <Gallery/>
                </Layout>
              </>}
            />
            </Switch>
        </Router>



      </Provider>
    </div>
  );
}

export default App;
