import React, { Fragment, useEffect, useState, useContext } from "react";
import styled, { css, withTheme } from "styled-components";
// import { Context } from "../context";
import { Link, withRouter } from "react-router-dom";
import uniqid from "uniqid";
// import "./swiper.css";

function SliderInner(props) {
  // const context = useContext(Context);

  const { blockId = uniqid(), thumbsFor, children, template, arrows = true } = { ...props };

  const [slideNum, setSlideNum] = useState(children ? children.length : 0);

  const [uniqueId, setUniqueId] = useState(uniqid());

  useEffect(() => {
    if (children && children.length !== slideNum) {
      setSlideNum(children.length);
      global[`mySwiper_${blockId}`] && global[`mySwiper_${blockId}`].update();
    } else {
      global[`mySwiper_${blockId}`] && global[`mySwiper_${blockId}`].update();
    }
  }, [props]);

  useEffect(() => {

    const swiperSettings = {
      main: `var mySwiper_${blockId} = new Swiper('#${blockId}', {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView : 1,
        direction: 'horizontal',
        centeredSlides: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        setWrapperSize: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      });`,

      th: `var mySwiper_${blockId} = new Swiper('#${blockId}', {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.1,
        //preventClicks: false,
        preventClicksPropagation: false,
        thumbs: {swiper: mySwiper_${thumbsFor}},
        setWrapperSize: true,
        slideToClickedSlide: true,
        //freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
      mySwiper_${blockId}.controller.control = mySwiper_${thumbsFor};
      mySwiper_${thumbsFor}.controller.control = mySwiper_${blockId};

      `
    }

      const script = document.createElement("script");
      script.async = true;
      script.innerHTML = swiperSettings[blockId];
      document.body.appendChild(script);


  }, [blockId]);

  return (
    <>
      {/* <CollectionInfo/> */}

      <div
        id={`${blockId}`}
        className={`swiper-container swiper-container-horizontal swiper-container-autoheight ${
          template ? `swiper-container--${template}` : ""
        }`}
        style={{
          cursor: "grab",
        }}
      >
        <div
          className={`swiper-wrapper ${
            template ? `swiper-wrapper--${template}` : ""
          }`}
        >
          {children}

          {/* {!children && <div style={{ height: 80 }}>123</div>} */}
        </div>

        <div className="swiper_controls0">
          <div
            id="w-node-_21bed94f-ef5a-b0c2-f145-26cd08ec3aad-6b3bfaee"
            className="swiper_controls"
          >


<div className="swiper-pagination">
              <span className="swiper-pagination-bullet" />
            </div>

            {arrows && <div className="swiper-button-prev swiper-button-white">
              <div className="swiper-button0 w-embed">
                {/* <svg
                  width={10}
                  height={15}
                  viewBox="0 0 10 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.21282 0.859299C9.60334 1.24982 9.60334 1.88299 9.21282 2.27351L3.98633 7.5L9.21281 12.7265C9.60334 13.117 9.60334 13.7502 9.21282 14.1407L8.81062 14.5429C8.4201 14.9334 7.78693 14.9334 7.39641 14.5429L1.06062 8.20711C0.670099 7.81658 0.670098 7.18342 1.06062 6.79289L7.39641 0.457108C7.78693 0.0665833 8.4201 0.0665825 8.81062 0.457107L9.21282 0.859299Z"
                    fill="currentColor"
                  />
                </svg> */}
              </div>
            </div>}


            {arrows && <div className="swiper-button-next swiper-button-white">
              <div className="swiper-button0 w-embed">
                {/* <svg
                  width={10}
                  height={15}
                  viewBox="0 0 10 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.18938 0.457107C1.5799 0.0665825 2.21307 0.0665825 2.60359 0.457107L8.93938 6.79289C9.3299 7.18342 9.3299 7.81658 8.93938 8.20711L2.60359 14.5429C2.21307 14.9334 1.5799 14.9334 1.18938 14.5429L0.787185 14.1407C0.396661 13.7502 0.396661 13.117 0.787185 12.7265L6.01367 7.5L0.787185 2.27351C0.396661 1.88299 0.396661 1.24982 0.787185 0.859299L1.18938 0.457107Z"
                    fill="currentColor"
                  />
                </svg> */}
              </div>
            </div>}
          </div>
        </div>

        {/* <div className="swiper-pagination swiper-pagination-bullets">
        <span className="swiper-pagination-bullet" />
      </div> */}
      </div>
    </>
  );
}

export function SlideInner(props) {
  const {
    children,
    history,
    match,
    location,
    categoryName = "berber",
  } = { ...props };

  // const context = useContext(Context);

  return (
    <>
      <div className="swiper-slide">
        <div className="s_slide">
          <div className="c_block c_slider">
            <div className="s_cont">{children}</div>
          </div>
        </div>

        {/* <div className="bg bg--sl" /> */}
      </div>
    </>
  );
}

export const Slider = withRouter(SliderInner);
export const Slide = withRouter(SlideInner);
