import React, { useState, useEffect } from "react";
import { AIRTABLE_APIKEY, AIRTABLE_BASE } from "./config";

const Airtable = require("airtable");

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: AIRTABLE_APIKEY,
});
const base = Airtable.base(AIRTABLE_BASE);

const Context = React.createContext();

function Provider({ children }) {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (!gallery.length) {
      base("gallery")
        .select({
          maxRecords: 100,
          sort: [{ field: "importance", direction: "desc" }],
        })
        .eachPage(
          function page(records, fetchNextPage) {
            setGallery([
              ...gallery,
              ...records
                .filter((record) => record.get("active"))
                .map((record) => ({
                  id: record["id"],
                  image: record.get("image") ? record.get("image") : [],
                })),
            ]);
            // records.forEach(function(record) {
            //   console.log('Retrieved', record.get('Categories'));
            // });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    }
  }, [gallery]);

  return (
    <Context.Provider
      value={{
        state: {},
        data: {
          gallery,
        },
        handles: {},
      }}
    >
      {children}
    </Context.Provider>
  );
}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
